import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";

import Slider01 from "../../../images/propertyDetails/property01.jpg"
import Slider001 from "../../../images/propertyDetails/tab-property01.jpg"
import Slider002 from "../../../images/propertyDetails/tab-property02.jpg"
import Slider0001 from "../../../images/propertyDetails/desk-property01.jpg"
import Slider0002 from "../../../images/propertyDetails/desk-property02.jpg"
import Slider0003 from "../../../images/propertyDetails/desk-property03.jpg"

import Sliderdesk from "../../../images/propertyDetails/desktop.jpg"



import Slider from "react-slick";
import "./SimilarProperties.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const SimilarAreas = (props) => {
    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                <section className="similar-properties">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <h2><i>Other</i> area guides</h2>
                                <div className="slick-wrap">
                                <Slider {...settings}>
                                {props.areas && props.areas.map((area, i) => {
                                    return (
                                    <Link to={`../${area.URL}`}>
                                    <div className="properties img-zoom">
                                        <picture>
                                            <source media="(min-width:1500px)" srcSet={area.Tile_Image.url} />
                                            <source media="(min-width:992px)" srcSet={area.Tile_Image.url} />
                                            <source media="(min-width:768px)" srcSet={area.Tile_Image.url} />
                                            <img src={area.Tile_Image.url} alt={area.Title} />
                                        </picture>
                                        <div className="card-details">
                                            <h3>
                                                {area.Title}                                                
                                            </h3>
                                        </div>
                                    </div>
                                    </Link>
                                    )
                                })}
                                </Slider>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </ScrollAnimation>
        </React.Fragment>
    );
};

export default SimilarAreas;
