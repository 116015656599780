import React from "react";
import { graphql } from 'gatsby'
import { Link } from "@StarberryUtils";
import { Container, Row, Col } from "react-bootstrap";
import parse from 'html-react-parser';

import SEO from "../components/seo"
import Header from "../components/Header/Header"
import Modules from '../components/modules'
import Footer from "../components/Footer/Footer"
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";
import StaticBannerVideo from "../components/StaticPage/AreaGuideBanner"
import SimilarAreas from "../components/ProperyDetails/SimilarProperties/SimilarAreas"
import LocRatingMap from '../components/map/loc-rating-map-area-guides';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from "react-share";


const AreaGuideDetailsTemplate = (props) => {
    
  const GQLPage = props.data.glstrapi?.areaGuide;
  const OtherAreas = props.data.glstrapi?.areaGuides;

console.log("GQLPage", GQLPage)

    const getBreadcrumb = (list) => {
      let bread = [
        { Title: "About", Slug: "about-us" },
        { Title: "areas-we-cover", Slug: "" },
        { Title: GQLPage.Title, Slug: `${GQLPage.URL}` }
      ]
      return bread;
    }

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    const [Shareicons, setShareicons] = React.useState(false);
    const openShareicons = () => {
        setShareicons(true);
        if (Shareicons === true) {
            setShareicons(false);
        }
    }
    
    return (
        <div className="area-guide-details  news-details">
            <SEO 
                title={`${GQLPage.Title} Area Guide`} 
                description={`Know more about ${GQLPage.Title} here. Contact one of our property professionals to get assistance in finding the perfect property in [area].`} 
            />
            <Header/>
        <div className="collection-landing news-details">

        { /*GQLPage.Banner_Image &&
        <StaticBannerVideo {...GQLPage}/>
        */}

         <Breadcrumb staticList={getBreadcrumb()} />
        <div className={`content static-content-block  ${props.banner}`}>
            <Container>
                <Row>
                    <Col lg={1}>
                    </Col>
                    <Col sm={12} lg={10}>
                    <div className="heading">
                      <div className="main-title">
                           <h1>{GQLPage.Title}</h1>

                            <div className="social">                   
                                <a href="javascript:void(0)" className="propertyShare" onClick={openShareicons}>Share this guide</a>
                                {Shareicons &&
                                    <div onClick={openShareicons} className="social-share">
                                        <FacebookShareButton url={shareurl} className="my-share-button facebook-share">
                                            <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                        </FacebookShareButton>
                                        <TwitterShareButton url={shareurl} className="my-share-button twitter-share">
                                            <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                        </TwitterShareButton>

                                    </div>
                                }
                            </div>                           
                      </div>
                    </div>
                    {GQLPage.Tile_Image.url &&
                      <div className="news-main-banner">
                          <img src={GQLPage.Banner_Image.url} alt={GQLPage.Title} />
                            
                      </div>
                    }
                    {GQLPage.Content &&
                        <div className="row">
                            <div className="static-content col-lg-12">
                                {parse(GQLPage.Content)}
                            </div>
                        </div>
                    }
                    </Col>
                    <Col lg={1}>
                    </Col>
                </Row>
            </Container>
        </div>          
      
        {OtherAreas &&
            <div className="other-area-guides">
                <SimilarAreas areas={OtherAreas}/>
            </div>
        }
        </div>
        
          <Footer popular_search="area" area_name={GQLPage.Title}/>
        </div>
    )
}

export default AreaGuideDetailsTemplate





export const pageQuery = graphql`
  query AreaQuery($slug: ID!) {
    glstrapi {
        areaGuide(id: $slug, publicationState: LIVE) {
            id
            Title
            Content
            Latitude
            Longtitude
            Meta_Description
            Meta_Title
            Video_URL
            imagetransforms
            Tile_Image {
              url             
            }
            Banner_Image {
                url
                alternativeText
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(formats: AUTO
                      width: 1800
                      placeholder: BLURRED
                      quality: 80
                      layout: FIXED
                      transformOptions: {cropFocus: CENTER, fit: COVER}
                    )    
                    fluid(quality: 90, maxWidth: 1800) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }   
            }
        }

        areaGuides(where: {id_ne: $slug, Publish: true}, limit: 4) {
            Title
            URL
            Tile_Image {
              url
              alternativeText
            }
            Banner_Image {
              url
            }
            imagetransforms
          }
    }
  }
`